<script setup>
import OInfoItems from 'o365.vue.components.InfoItems.vue';


const props = defineProps({
    dataObject: Object,
    dataGridControl: Object
});

</script>

<script>
export default {
    name: 'ODataGridStatusBar'
}
</script>

<template>
    <div class="o365-footer py-1 p-1 d-flex">
        <slot>
            <OInfoItems :dataObject="dataObject" layoutStatus :dataGridControl="dataGridControl"
                :noRefreshButton="dataGridControl.props.data && dataGridControl.props.gridApi?.load == null">
                <template v-if="$slots.actionsEnd" #actionsEnd>
                    <slot name="actionsEnd"></slot>
                </template>
            </OInfoItems>
        </slot>
    </div>
</template>